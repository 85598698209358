<template>
    <div>
        <div class="mb-3">
            <router-link to="/admin/keywords" class="text-decoration-none">
                <b-button type="is-light" size="is-small" icon-left="backward">
                    Retour à la liste des mots-clés
                </b-button>
            </router-link>
        </div>

        <div class="box shadow">
            <Toaster ref="toaster" />
            <div class="box-header">
                <nl-alert ref="alert" />
                <h3 class="box-title d-flex align-items-center">
                    Ajouter des mots-clés FR
                    <img src="/assets/img/lang/fr_fr.png" style="width: 30px" class="ml-2" alt="[FR]" />
                </h3>
                <h6>Format : mot-clé[&#60;tabulation&#62;volume]</h6>
                <div class="form-group">
                    <textarea
                        v-model="keywords.fr"
                        class="form-control"
                        rows="6"
                        @keydown.tab.prevent="keywords.fr += '\t'"
                    />
                </div>
            </div>
            <div class="box-header">
                <h3 class="box-title d-flex align-items-center">
                    Ajouter des mots-clés EN
                    <img src="/assets/img/lang/com_en.png" style="width: 30px" class="ml-2" alt="[FR]" />
                </h3>
                <h6>Format : mot-clé[&#60;tabulation&#62;volume]</h6>
                <div class="form-group">
                    <textarea
                        v-model="keywords.en"
                        class="form-control"
                        rows="6"
                        @keydown.tab.prevent="keywords.en += '\t'"
                    />
                </div>
            </div>
            <button class="btn btn-secondary mt-5" :disabled="disabled" @click.prevent="sendKeywords">
                <i class="fas fa-check-circle mr-2" />Ajouter les mots-clés
            </button>
        </div>
    </div>
</template>

<script>
    import ApiService from '../../../services/api.service'

    export default {
        name: 'KeywordAdd',
        title: 'global.addkeywords',
        data() {
            return {
                keywords: {
                    en: '',
                    fr: ''
                }
            }
        },
        computed: {
            keywordsFr() {
                return this.keywords.fr.split('\n').flatMap(keywordLine => {
                    return this.setKeywordFromLine(keywordLine, 'fr_fr')
                })
            },
            keywordsEn() {
                return this.keywords.en.split('\n').flatMap(keywordLine => {
                    return this.setKeywordFromLine(keywordLine, 'en_en')
                })
            },
            disabled() {
                return this.keywordsFr.length === 0 && this.keywordsEn.length === 0
            }
        },
        methods: {
            setKeywordFromLine(keywordLine, lang) {
                if (keywordLine === '') return []
                let [keywordString, volume] = keywordLine.split('\t')
                if (keywordString === '') return []

                let keyword = {
                    keyword: keywordString,
                    lang
                }

                if (volume !== '' && volume !== undefined) {
                    keyword = {
                        ...keyword,
                        volume: volume
                    }
                }

                return keyword
            },
            sendKeywords() {
                // TODO disable submit button
                let keywords = this.keywordsFr.concat(this.keywordsEn)
                ApiService.post('/admin/keywords/bulk', { keywords })
                    .then(({ data }) => {
                        this.keywords.fr = ''
                        this.keywords.en = ''

                        this.$buefy.snackbar.open({
                            message: data.message,
                            position: 'is-top',
                            indefinite: true
                        })
                    })
                    .catch(({ response }) => {
                        if (response.status === 422) {
                            this.$refs.alert.showValidationError(response.data)
                        } else {
                            this.$refs.alert.displayMsg('danger', [response.data.message])
                        }
                    })
            }
        }
    }
</script>

<style scoped></style>
